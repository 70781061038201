import React from "react";
import Image from "./Svg_Files/image (3).svg";
import './WhyCompreo.css';
import { Row, Col } from 'antd';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';

const WhyCompreo = () => {
    return (
        <div>
            <div className='why-compreo-parent'>
                <div className='why-compreo-container'>
                    <Row gutter={[24,24]} align="middle" justify="center">
                        <Col
                            xs={24}  // Full width on small screens
                            sm={24}
                            md={16}  // 70% width on medium screens
                            lg={16}  // 70% width on large screens
                            xl={16}  // 70% width on extra-large screens
                        >
                            <div className='why-compreo-text'>
                                <div className='why-compreo-heading'>
                                    Why <span>Compreo.ai?</span>
                                </div>
                                <div className='why-compreo-subtext'>
                                    Compreo’s all-in-one ERP solution empowers businesses to streamline operations, boost efficiency, and drive growth. Compreo offers a comprehensive suite of tools tailored for various industries, including manufacturing, retail, financial services, healthcare, and more. With robust features like automated workflows, integrated CRM, and inventory management, Compreo ensures that every aspect of your business is optimized for performance. Its user-friendly interface and flexible architecture allow seamless integration with existing systems, making it easy to adopt and scale. Compreo also prioritizes data security and compliance, helping businesses navigate complex regulatory environments with ease. Backed by exceptional customer support and continuous innovation, Compreo is the ideal choice for companies looking to stay competitive and future-ready.
                                </div>
                            </div>
                        </Col>
                        <Col
                            xs={24}  // Full width on small screens
                            sm={24}
                            md={8}   // 30% width on medium screens
                            lg={8}   // 30% width on large screens
                            xl={8}   // 30% width on extra-large screens
                        >
                            <div className='why-compreo-image'>
                                <img src={Image} alt="ALternalty_image"/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='Idea'>
                <div className='Idea-container'>
                    <div className='Idea-container-heading'>The Idealogy</div>
                    <div className='Idea-container-subtext-1'>Venkat Duddukuri possesses extensive expertise in major ERP systems such as SAP and Oracle Apps. However, he identified a critical issue: these ERP solutions frequently present substantial usability challenges for end users lacking robust technical proficiency. Venkat asserted that ERP systems should not impose such operational complexities.</div>

                    <div className='Idea-container-subtext-2'>Through his extensive collaboration with end users and stakeholders, Venkat gained deep insights into their challenges. Many ERP users possess limited technical expertise, which can result in these systems impeding rather than facilitating organizational operations. Small and medium-sized enterprises (SMEs) often lack the financial resources to hire costly technical personnel, and implementing major ERP solutions can be prohibitively expensive. In response to this, Venkat envisioned and developed Compreo.ai-a no-code ERP solution designed to be both user-friendly and cost-effective, allowing for implementation with minimal budget and time investment.</div>
                    <div className='Idea-container-footer'>
                        <div className='Idea-container-footer-container'>

                            <div className='Idea-container-footer-text'>“We believe that technology is not just about <span>Innovation</span>; it’s about holistic <span>Transformation</span>.”</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='whychoosecompreo-parent'>
                <div className='whychoosecompreo-container'>
                    <div className='whychoosecompreo-container-text'>
                        <div className='whychoosecompreo-container-text-heading'>Why choose Compreo?</div>
                        <div className='whychoosecompreo-container-text-subtext'>
                            Managing a business is challenging enough without the added stress of complicated software.
                            That's why Compreo.ai provides an ERP system that adapts to your needs, ensuring a seamless and intuitive experience.
                        </div>
                    </div>
                    <Row gutter={[32, 48]} className='whychoosecompreo-container-cards'>
                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                            <div className='whychoosecompreo-container-cards-item'>
                                <div className='whychoosecompreo-container-cards-index'>01</div>
                                <div className='whychoosecompreo-container-cards-heading'>Fast Set-up</div>
                                <div className='whychoosecompreo-container-cards-subtext'>Launch in days, not months</div>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                            <div className='whychoosecompreo-container-cards-item'>
                                <div className='whychoosecompreo-container-cards-index'>02</div>
                                <div className='whychoosecompreo-container-cards-heading'>User Friendly</div>
                                <div className='whychoosecompreo-container-cards-subtext'>Designed for real people, not for IT</div>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                            <div className='whychoosecompreo-container-cards-item'>
                                <div className='whychoosecompreo-container-cards-index'>03</div>
                                <div className='whychoosecompreo-container-cards-heading'>Smart Insights</div>
                                <div className='whychoosecompreo-container-cards-subtext'>Data driven decisions at every step</div>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                            <div className='whychoosecompreo-container-cards-item'>
                                <div className='whychoosecompreo-container-cards-index'>04</div>
                                <div className='whychoosecompreo-container-cards-heading'>Flexible Growth</div>
                                <div className='whychoosecompreo-container-cards-subtext'>Scales seamlessly with your business</div>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                            <div className='whychoosecompreo-container-cards-item'>
                                <div className='whychoosecompreo-container-cards-index'>05</div>
                                <div className='whychoosecompreo-container-cards-heading'>Cost effective</div>
                                <div className='whychoosecompreo-container-cards-subtext'>Enterprise power, SME price</div>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                            <div className='whychoosecompreo-container-cards-item'>
                                <div className='whychoosecompreo-container-cards-index'>06</div>
                                <div className='whychoosecompreo-container-cards-heading'>Work anywhere </div>
                                <div className='whychoosecompreo-container-cards-subtext'>Full functionality on mobile</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}
        </div>
    );
};

export default WhyCompreo;
