import React from 'react';
import { Row, Col, Card } from 'antd';
import "../ERP/Erp.scss"
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from '../LandingPage/Contact_us/Contact';
import Our_branches from '../LandingPage/Our_branches/branch';
import GeneralQuestions from "../LandingPage/GeneralQuestions/GeneralQuestions";
import EasyBusiness from '../LandingPage/EasyBusiness/EasyBusiness';
import EndtoendClouderp from "../LandingPage/EndtoendClouderp/EndtoendClouderp";
import Image222 from './Svg_files/CRM.svg'
import Customize from './Svg_files/view-user 1.svg';
import Engineering from './Svg_files/automation 1.svg';
import Analytics from './Svg_files/dashboard 1.svg';
import Report from './Svg_files/data-integration 1.svg';
import Coverage from './Svg_files/integration.svg';
import MobileApli from './Svg_files/check.svg';
import Image1 from './Svg_files/image1.svg';
import Arrow from './Svg_files/Arrow 1.svg'
import CompreoImg from './Svg_files/CompreoOffers.svg';
import Customization from './Svg_files/Customization.svg';
import Integration from './Svg_files/Integration copy.svg';
import CustomerService from './Svg_files/CustomerService.svg';
import Rectangle1 from './Svg_files/Rectangle1.svg';
import Rectangle2 from './Svg_files/Rectangle2.svg';
import Rectangle3 from './Svg_files/Rectangle3.svg';
import EsaybusinessBackground from '../EsaybusinessBackground/EsaybusinessBackground';


const Crm = () => {

    const features = [
        {
            imgSrc: Customize,
            heading: "Unified Customer View",
            subtext: "All your customer interactions and data are converged into a single, easily accessible platform for improved relationship management.  "
        },
        {
            imgSrc: Engineering,
            heading: "Automated Workflows",
            subtext: "Automated tasks, follow-ups, and reminders streamline your operations and allow your team to focus on what matters most. "
        },
        {
            imgSrc: Analytics,
            heading: "Customizable Dashboards",
            subtext: "Our customizable dashboards and reports helps you personalize your CRM interface to track key metrics and performance indicators. "
        },
        {
            imgSrc: Report,
            heading: "Seamless Integration",
            subtext: "Integrate effortlessly with other tools and systems, including email, social media, and marketing platforms, to enhance your business processes. "
        },

    ];

    const Subheading = 'Our CRM offers a complete end-to-end solution that simplifies customer relationship management. From initial contact through sales and creation of account on to Compreo’s ERP, our CRM provides the tools and insights you need to nurture leads, enhance customer interactions, and drive business growth effortlessly.'

    const cardData = [
        { title: 'Enhanced Customer Engagement', content: 'Build and maintain strong relationships with personalized communication and targeted marketing' },
        { title: 'Increased Efficiency', content: 'Automate routine tasks and workflows to free up time for your team and reduce manual errors.' },
        { title: 'Scalable Solution', content: 'Grow your CRM capabilities as your business expands, with flexible features and integration options' },
        { title: 'Improved Collaboration', content: 'Facilitate seamless communication and collaboration among your team members with centralized information' },
    ];

    const cards = [
        {
            title: 'OCR Capability',
            description: 'Transform documents into data',
            image: Rectangle1,
        },
        {
            title: 'Automation Tools',
            description: 'Let the software handle repetitive tasks',
            image: Rectangle2,
        },
        {
            title: 'Real-Time Analytics',
            description: 'Make informed decisions faster',
            image: Rectangle3,
        },
    ];

    const questions = [
        { question: 'Can Compreo CRM be customized to fit my business needs? ', answer: 'Yes, Compreo CRM is highly customizable. You can tailor dashboards, reports, and workflows to align with your specific business requirements and preferences. ' },
        { question: 'How does Compreo CRM integrate with other tools and systems? ', answer: 'Our CRM integrates seamlessly with a variety of tools and systems, including email platforms, social media, and marketing software, ensuring a smooth flow of information across your business processes. ' },
        { question: 'What kind of support is available for Compreo CRM users? ', answer: 'We offer comprehensive support for our CRM users, including setup assistance, training, and ongoing technical support to ensure you get the most out of our system. ' },
        { question: 'Is Compreo CRM suitable for businesses of all sizes? ', answer: 'Yes, Compreo CRM is designed to be scalable and adaptable, making it suitable for businesses of all sizes, from small startups to large enterprises. ' },
        { question: 'How does Compreo CRM help improve customer service? ', answer: 'By consolidating customer data and automating routine tasks, Compreo CRM enables you to provide timely and personalized service, track customer interactions, and resolve issues more efficiently. ' },
    ];

    return (
        <div className='about-parent'>
            <div className='startEngaging-parent'>

                <div className='startEngaging-container' style={{
                    backgroundImage: `url(${Image222})`,
                }}>
                    <div
                        className='startEngaging-container-text'>
                        <div className='startEngaging-container-heading'>
                            Difficulty managing customer data? Compreo’s CRM centralizes and simplifies interactions
                        </div>
                        <div className='startEngaging-container-subtext'>
                            Compreo's CRM, an intelligent technology built to streamline customer interactions, increase sales, and drive growth, will help you realize your full business potential. Our AI-powered CRM gives you an exhaustive overview of your customer data, automates routine tasks, and provides actionable insights to help you develop deeper, more valuable connections with your customers.
                        </div>
                    </div>
                </div>
            </div>
            <div className="kf-container">
                <Row gutter={[24, 24]}>
                    <Col xs={24} md={10} lg={10} xl={10} className="kf-container-left">
                        <div className="kf-container-heading">Key Features</div>
                        <div className="kf-container-subtext">
                            Enhance customer relationships with Compreo’s CRM, a powerful solution designed to streamline interactions and drive growth. Compreo’s CRM centralizes customer data, automates routine tasks, and offers valuable insights, enabling your team to focus on building meaningful connections. This comprehensive tool helps improve engagement, boosts sales, and supports data-driven decisions, maximizing your business’s potential.
                        </div>
                    </Col>
                    <Col xs={24} md={14} lg={14} xl={14} className="kf-cards">
                        <Row gutter={[24, 24]} className="kf-cards-parent">
                            {features.map((feature, index) => (
                                <Col
                                    xs={24}
                                    sm={12}
                                    md={10}
                                    lg={10}
                                    xl={10}
                                    key={index}
                                    className="kf-cards-container"
                                >
                                    <div className="kf-cards-container-img">
                                        <img src={feature.imgSrc} alt={feature.heading} />
                                    </div>
                                    <div className="kf-cards-container-heading">
                                        {feature.heading}
                                    </div>
                                    <div className="kf-cards-container-subtext">
                                        {feature.subtext}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="catalyst-container">
                <div className="catalyst-content">
                    <div className="catalyst-points">
                        <ul className="catalyst-points-ul">
                            <li>Our CRM transforms business operations by centralizing all customer data into a single, unified platform, providing a clear overview of every interaction and enhancing overall customer relationship management.​</li>
                            <li>It streamlines operations through automation, handling routine tasks such as follow-ups, reminders, and reporting. This allows teams to concentrate on high-value activities like customer engagement and closing deals. ​</li>
                            <li>The CRM delivers actionable insights, helping you identify trends, monitor performance, and adjust strategies for better results. These data-driven insights enable more effective decision-making and business growth.</li>
                            <li>With improved efficiency, collaboration, and performance tracking, our CRM boosts productivity across your organization. It empowers businesses to optimize their processes, enhance customer satisfaction, and drive sustainable growth in a competitive market.​</li>
                        </ul>
                    </div>
                    <div className="catalyst-title spacingAlignCatalist">
                        <div className="catalyst-title-heading">Catalyst for your Business Operations</div>
                        <div className="catalyst-image">
                            <img src={Image1} alt="Catalyst" />
                        </div>
                    </div>

                </div>
            </div>
            <div className='CompreoOfferAlignment'>
                <Row gutter={[16, 16]} className='OffersAlignment'>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={10}
                        xl={11}
                    >
                        <div className='OffersAlignment-image'>
                            <img src={CompreoImg} alt="CompreoOffers" className="CompreoOffers" />
                        </div>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={14}
                        xl={12}
                    >
                        <div >
                            <h2 className='ProductStandstext'>What makes our product stands out from the rest?</h2>
                            <h4 className='BusinesOffersText'>Difficulty managing customer data? Our CRM centralizes and simplifies interactions.</h4>
                            <div className="catalyst-points backrdnone">
                                <ul className="catalyst-points-ul listSPacing">
                                    <li >
                                        {/* <img src={Customization} alt="CompreoOffers" className='OfferIcon' /> */}
                                        Centralizes data and automates tasks.
                                    </li>
                                    <li >
                                        {/* <img src={Integration} alt="CompreoOffers" className='OfferIcon' /> */}
                                        Provides insights to enhance sales and service.
                                    </li>
                                    <li >
                                        {/* <img src={CustomerService} alt="CompreoOffers" className='OfferIcon' /> */}
                                        Streamlines operations for increased efficiency.
                                    </li>
                                    <li>Boosts productivity and drives growth.</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <EsaybusinessBackground cardData={cardData} Subheading={Subheading} />

            {/* <EndtoendClouderp />*/}
            <GeneralQuestions questions={questions} />
            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />
        </div>
    );
};

export default Crm;
