import React from 'react';
import Layout from './Layout/Layout';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Screens/LandingPage/Home';
import EcommercePortal from './Screens/EcommercePortal/EcommercePortal';
import VendorPortal from './Screens/VendorPortal/VendorPortal';
import WarehouseManagement from './Screens/WarehouseManagement/WarehouseManagement';
import SupplyChainManagement from './Screens/SupplyChainManagement/SupplyChainManagement';
import CustomerPortal from './Screens/CustomerPortal/CustomerPortal';
import EmployeePortal from './Screens/EmployeePortal/EmployeePortal';
import BuildUpMaterials from './Screens/BuildUpMaterials/BuildUpMaterials';
import ClubManagement from './Screens/ClubManagement/ClubManagement';
import FinancialAccounting from './Screens/FinancialAccounting/FinancialAccounting';
import SalesDistribution from './Screens/SalesDistribution/SalesDistribution';
import InventoryManagement from './Screens/InventoryManagement/InventoryManagement';
import MaterialPurchaseManagement from './Screens/MaterialPurchaseManagement/MaterialPurchaseManagement';
import Procurement from './Screens/Procurement/Procurement';
import HrAndPayroll from './Screens/HrAndPayroll/HrAndPayroll';
import ProductionPlanning from './Screens/ProductionPlanning/ProductionPlanning';
import ProjectPlanning from './Screens/ProjectPlanning/ProjectPlanning';
import ProjectManagement from './Screens/ProjectManagement/ProjectManagement';
import FixedAssets from './Screens/FixedAssets/FixedAssets';
import PaymentIntegration from './Screens/PaymentIntegration/PaymentIntegration';
import QualityManagement from './Screens/QualityManagement/QualityManagement';
import PointOfSale from './Screens/PointOfSale/PointOfSale';
import Pricing from './Screens/Pricing/Pricing';
import WhyChooseUs from './Screens/WhyChooseUs/WhyChooseUs';
import OurTechnologies from './Screens/OurTechnologies/OurTechnologies';
import OurCompany from './Screens/OurCompany/OurCompany';
import ProjectSystems from './Screens/ProjectSystems/ProjectSystems';
import WhyCompreo from './Screens/WhyCompreo/WhyCompreo';
import OurTeam from './Screens/OurTeam/OurTeam';
import ContactForm from './Screens/LandingPage/ContactForm/ContactForm';
import ContactUs from './Screens/LandingPage/Contact_us/Contact';
import Manufacturing from './Screens/Manufacturing/Manufacturing';
import Distribution from './Screens/Distribution/Distribution';
import Hospitality from './Screens/Hospitality/Hospitality';
import Retail from './Screens/Retail/Retail';
import Communication from './Screens/Communication/Communication';
import MediaEntertainment from './Screens/MediaEntertainment/MediaEntertainment';
import FinancialServices from './Screens/FinancialServices/FinancialServices';
import HealthIndustry from './Screens/HealthIndustry/HealthIndustry';
import Education from './Screens/Education/Education';
import Power_Energy from './Screens/Power_Energy/Power_Energy';
import Pharma_Lifesciences from './Screens/Pharma_Lifesciences/Pharma_Lifesciences';
import Erp from './Screens/ERP/Erp';
import Crm from './Screens/CRM/Crm';
import Pos from './Screens/POS/Pos';
import ScrollToTop from './Routes/ScrollTop';

export default class App extends React.Component {

  render() {
    return (
      <Router>
        <ScrollToTop/>
        <Layout {...this.props}>
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/e-commerce-portal" element={<EcommercePortal />} />
            <Route path="/vendor-portal" element={<VendorPortal />} />
            <Route path="/warehouse-management" element={<WarehouseManagement />} />
            <Route path="/supply-chain-management" element={<SupplyChainManagement />} />
            <Route path="/Dealer-portal" element={<CustomerPortal />} />
            <Route path="/Employee-Self-Service-Portal" element={<EmployeePortal />} />
            {/* <Route path="/Machine-maintenance" element={<MachineMaintainence />} /> */}
            <Route path="/bill-of-materials" element={<BuildUpMaterials />} />
            <Route path="/club-management" element={<ClubManagement />} />
            <Route path="/financial-management" element={<FinancialAccounting />} />
            <Route path="/sales-distribution" element={<SalesDistribution />} />
            <Route path="/inventory-Management" element={<InventoryManagement />} />
            <Route path="/Material-management" element={<MaterialPurchaseManagement />} />
            <Route path="/procurement" element={<Procurement />} />
            <Route path="/hr-payroll" element={<HrAndPayroll />} />
            <Route path="/production-planning" element={<ProductionPlanning />} />
            <Route path="/project-planning" element={<ProjectPlanning />} />
            <Route path="/project-management" element={<ProjectManagement />} />
            <Route path="/fixed-assets" element={<FixedAssets />} />
            <Route path="/payment-integration" element={<PaymentIntegration />} />
            <Route path="/quality-control" element={<QualityManagement />} />
            <Route path="/point-of-sale" element={<PointOfSale />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/why-choose-us" element={<WhyChooseUs />} />
            <Route path="/our-technologies" element={<OurTechnologies />} />
            <Route path="/about-company" element={<OurCompany />} />
            <Route path="/project-systems" element={<ProjectSystems />} />
            <Route path="/why-compreo" element={<WhyCompreo />} />
            <Route path="/our-team" element={<OurTeam />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/Contact-us" element={<ContactForm />} />
            <Route path="/Manufacturing" element={<Manufacturing />} />
            <Route path="/Distribution-Supply-Chain" element={<Distribution />} />
            <Route path="/Hospitality" element={<Hospitality />} />
            <Route path="/Retail" element={<Retail />} />
            <Route path="/Communication" element={<Communication />} />
            <Route path="/media-entertainment" element={<MediaEntertainment />} />
            <Route path="/financial-services" element={<FinancialServices />} />
            <Route path="/health-Care" element={<HealthIndustry />} />
            <Route path="/Education" element={<Education />} />
            <Route path="/Energy-Utilities" element={<Power_Energy />} />
            <Route path="/Pharmaceutical" element={<Pharma_Lifesciences />} />
            <Route path="/ERP" element={<Erp />} />
            <Route path="/POS" element={<Pos />} />
            <Route path="/CRM" element={<Crm />} />
          </Routes>
        </Layout>
      </Router>
    );
  }
}